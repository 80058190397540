<template>
  <div class="stories-preview-overlay">

    <div class="preview-stories">
      <div
        v-if="adz"
        class="approbation-bt-stories"
        :class="{
          active: adz.isApproved,
        }"
        @click.stop="approveAdz(adz)"
      >
        <p>Approved</p>
        <div class="round">
          <svg width="10" height="8" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.0953 0L12.143 1.00802C10.0036 3.27701 7.73915 5.83967 5.60223 8.14579L2.74509 5.65537L1.70868 4.75112L0 6.95245L1.03641 7.85671L4.84593 11.1773L5.79132 12L6.65267 11.0809C9.07037 8.5165 11.685 5.52279 14.0476 3.01672L15 2.0087L13.0953 0Z"
              fill="white"
            />
          </svg>
        </div>
      </div>

      <SimpleProduit
        v-if="adz"
        :adz="adz"
        :client="client"
        :is-saving="false"
        :upload-progress="0"
        :show-approve="true"
        :account-id="accountId"
        :client-id="clientId"
        :campaign-id="campaignId"
        :adz-id="previewStories.id"
        :refresh-adz-view="refreshAdzView"
        :platform="'facebook'"
      />
    </div>

    <button
      v-if="allStoriesAdz.length > 1"
      @click="prevAdz()" class="slider-left"
    >
      <img src="@/assets/img/slider-left.svg" alt="Left" />
    </button>

    <button
      v-if="allStoriesAdz.length > 1"
      @click="nextAdz()" class="slider-right"
    >
      <img src="@/assets/img/slider-right.svg" alt="Right" />
    </button>

    <a v-if="!hidePreviewClose" href="#" class="close"><img src="@/assets/img/close.svg" alt="Close" @click.prevent="closeStoriesPreview()" /></a>

    <div class="stories-list">
      <div class="title-wrap">
        <p class="story-title">Stories</p>
        <p class="beta">Beta</p>
      </div>

      <div class="link-wrap" v-if="allStoriesAdz.length > 1 && adz">
        <a
          v-for="(item, i) in allStoriesAdz"
          :key="'sl-' + i"
          :class="{ 'active': item.id === adz.id }"
          @click.prevent="switchAdz(item)"
          href="#"
        >
          {{ item.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { isNil } from 'lodash'
import { mapState, mapActions } from 'vuex'
import AccountClientCampaignAdzDB from '@/firebase/account-client-campaign-adz-db'
import SimpleProduit from '@/components/facebook/SimpleProduit'

export default {
  name: 'StoriesPreview',

  props: {
    closeStoriesPreview: {
      type: Function,
      required: true,
    },
    previewStories: {
      type: Object,
      required: true,
    },
    allStoriesAdz: {
      type: Array,
      required: true,
    },
    client: {
      type: Object,
      required: true,
      default: null,
    },
    accountId: {
      type: String,
      required: true,
      default: null,
    },
    clientId: {
      type: String,
      required: true,
      default: null,
    },
    campaignId: {
      type: String,
      required: true,
      default: null,
    },
    hidePreviewClose: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    SimpleProduit,
  },

  watch: {
    previewStories: {
      async handler(previewStories) {
        if (!isNil(previewStories)) {
          this.fetchAdzInfos()
        }
      },
      immediate: true,
    },
  },

  data() {
    return {
      adz: null,
    }
  },

  computed: {
    adzId() {
      return this.previewStories.id
    },
    ...mapState('app', ['appTitle']),
    ...mapState('users', ['account', 'selectedClient', 'selectedCampaign', 'selectedAdz']),
  },

  methods: {
    async fetchAdzInfos() {
      const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.accountId, this.clientId, this.campaignId)
      this.adz = await accountClientCampaignAdzDbRef.read(this.previewStories.id)
    },

    browserBack() {
      return this.$router.go(-1)
    },

    next() {
      this.$refs.flickity.next()
    },

    previous() {
      this.$refs.flickity.previous()
    },

    switchAdz(adz) {
      this.adz = null

      setTimeout(() => {
        this.adz = adz
      }, 111)
    },

    prevAdz() {
      let currentIndex = this.allStoriesAdz.indexOf(this.adz)
      currentIndex -= 1

      if (currentIndex < 0) {
        currentIndex = this.allStoriesAdz.length - 1
      }

      this.switchAdz(this.allStoriesAdz[currentIndex])
    },

    nextAdz() {
      let currentIndex = this.allStoriesAdz.indexOf(this.adz)
      currentIndex += 1

      if (currentIndex > this.allStoriesAdz.length - 1) {
        currentIndex = 0
      }

      this.switchAdz(this.allStoriesAdz[currentIndex])
    },

    async approveAdz(adz) {
      const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.accountId, this.clientId, this.campaignId)

      const approvedAdz = { ...adz, isApproved: !this.adz.isApproved }

      await accountClientCampaignAdzDbRef.update(approvedAdz)

      this.refreshAdzView(adz)
    },

    async refreshAdzView(adz = false) {

      let tAdz = adz

      if (!tAdz) {
        tAdz = this.adz
      }

      const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.accountId, this.clientId, this.campaignId)
      this.adz = await accountClientCampaignAdzDbRef.read(tAdz.id)
      this.adz = {...this.adz, ping: new Date()}
    },

    ...mapActions('users', ['selectClient', 'selectCampaign', 'selectAdz']),
  },
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/forms.scss';
@import '@/theme/button.scss';

.stories-preview-overlay {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 4500;
  background: #e9ebee;
}

.page-Stories {
  background-color: #e9ebee;

  .navbar {
    display: none;
  }
}

.close {
  position: absolute;
  top: 25px;
  right: 30px;

  img {
    width: 15px;
  }
}

.stories-list {
  position: absolute;
  top: 25px;
  left: 30px;

  .story-title {
    font-weight: 400;
    font-size: 20px;
    color: $dark-grey;
  }

  .title-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .beta {
    background: linear-gradient(to right, #2e7df2, #8a23f1);
    color: #fff;
    font-size: 13px;
    border-radius: 3px;
    padding: 5px 8px;
    font-weight: 400;
    margin-left: 10px;
  }

  .link-wrap {
    width: 350px;
    background-color: #fff;
    border: 1px solid #e0e2e6;
    border-radius: 3px;

    a {
      width: 100%;
      display: block;
      font-size: 13px;
      font-weight: 600;
      color: $dark-grey;
      border-bottom: 1px solid #e0e2e6;
      padding: 15px 10px;
      transition: all 0.2s ease;

      &:hover,
      &.active {
        background-color: $grey;
      }

      &:last-child {
        border: 0;
      }
    }
  }
}

.preview-stories {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -54.5%);
  border-radius: 10px;
  z-index: 2;
  // overflow: hidden;
}

.slider-left {
  background: transparent;
  width: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-241px, -120%);
  cursor: pointer;
  z-index: 1;
}

.slider-right {
  background: transparent;
  width: 25px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(229px, -120%);
  cursor: pointer;
  z-index: 1;
}

.approbation-bt-stories {
  position: absolute;
  top: 0;
  right: -100px;
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    p {
      opacity: 1;
    }
    .round {
      opacity: 1;
    }
  }

  .round {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background-color: #fff;
    border: 2px solid $dark-grey;
    margin-left: 5px;
    opacity: 0.5;
  }

  svg path {
    fill: $dark-grey;
  }

  p {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: $dark-grey;
    opacity: 0.5;
  }

  &.active {
    p {
      opacity: 1;
    }
    .round {
      opacity: 1;
      background: linear-gradient(to right, #2bb0c5, #6bd8ba);
      border: 0;
    }
    svg path {
      fill: #fff;
    }
  }
}
</style>

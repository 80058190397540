<template>
  <img v-if="(adz.type === 'single') && adz.content.image" alt="Simple produit" class="ad-img" :src="fetchThumb(adz.content.imageRef, adz.content.imageRefFromId)" />

  <img v-else-if="(adz.type === 'stories') && adz.content.image" alt="Simple produit" class="ad-img" :src="fetchThumb(adz.content.imageRef, adz.content.imageRefFromId, false)" />

  <img
    v-else-if="adz.type === 'multiple' && adz.contentSlider && adz.contentSlider[0] && adz.contentSlider[0].image"
    alt="Simple produit"
    class="ad-img"
    :src="fetchThumb(adz.contentSlider[0].imageRef, adz.contentSlider[0].imageRefFromId)"
  />

  <video class="ad-video" v-else-if="(adz.type === 'single' || adz.type === 'stories') && adz.content && adz.content.videoMp4" muted poster="/">
    <source :src="fetchVideoThumb(adz.content.videoRef, adz.content.videoRefFromId) + '#t=2'" type="video/mp4" />
  </video>

  <video class="ad-video" v-else-if="adz.type === 'multiple' && adz.contentSlider && adz.contentSlider[0] && adz.contentSlider[0].videoMp4" muted poster="/">
    <source :src="fetchVideoThumb(adz.contentSlider[0].videoRef, adz.contentSlider[0].videoRefFromId) + '#t=2'" type="video/mp4" />
  </video>

  <img v-else alt="Simple produit" class="ad-img" src="@/assets/img/simple-product-placeholder.svg" />
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    adz: {
      type: Object,
      required: true,
      default: null,
    },
    appDomain: {
      type: String,
      required: true,
      default: '/',
    },
    sharedAccount: {
      type: Array,
      required: false,
    },
  },

  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('users', ['account']),
  },

  methods: {
    fetchThumb(fullPath, imageFromId = '', thumb = true) {
      let accountId = this.account && this.account[0] ? this.account[0].id : null
      if (!accountId) {
        accountId = this.sharedAccount[0].id
      }
      const adzId = imageFromId ? imageFromId : this.adz.id
      // https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/
      // homme-fr-bgap4TchhlUIv4CI57In.jpg?alt=media&token=d385c574-5401-4573-b56d-99417d9d1054
      let thumbPath = fullPath
      if (thumb) {
        thumbPath = thumbPath.replace('.jpg', '_600x600.jpg') // Thumbs format
        thumbPath = `https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/${accountId}%2Fadz%2f${adzId}%2Fthumbs2%2F${thumbPath}?alt=media`
      } else {
        thumbPath = `https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/${accountId}%2Fadz%2f${adzId}%2F${thumbPath}?alt=media`
      }
      return thumbPath
      // https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/4Wo75vIGxneCEvXnlrA6xH75f8u1%2Fadz%2Fbgap4TchhlUIv4CI57In%2Fthumbs2%2Fhomme-fr-bgap4TchhlUIv4CI57In_600x600.jpg?alt=media&token=d385c574-5401-4573-b56d-99417d9d1054
    },
    fetchVideoThumb(fullPath, imageFromId = '') {
      let accountId = this.account && this.account[0] ? this.account[0].id : null
      if (!accountId) {
        accountId = this.sharedAccount[0].id
      }
      const adzId = imageFromId ? imageFromId : this.adz.id
      // "https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/
      //  masques-XZ6LlFM3c2fgFHMcqsHK.mp4?alt=media&token=f8a6cafb-bc6c-4c8b-83a1-4359e761ff4d"
      let thumbPath = fullPath
      thumbPath = `https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/${accountId}%2Fadz%2f${adzId}%2F${thumbPath}?alt=media`
      return thumbPath
      // "https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/4Wo75vIGxneCEvXnlrA6xH75f8u1%2Fadz%2FXZ6LlFM3c2fgFHMcqsHK%2Fmasques-XZ6LlFM3c2fgFHMcqsHK.mp4?alt=media&token=f8a6cafb-bc6c-4c8b-83a1-4359e761ff4d
    },
  },
}
</script>

<style lang="scss" scoped></style>
